import voca from 'voca'
import { isEmpty, isString } from 'underscore'

export const validateEmail = (email) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export const validateSpanishZip = (zip) => {
  const trimmed = voca.trim(zip)
  return !isEmpty(trimmed) && isString(trimmed) && /^\d{5}$/.test(trimmed)
}
