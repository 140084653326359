import { has } from 'underscore'

export const orderMenus = (data) => {
  return data
}

const returnChildrenWithoutDuplicatedCustom = items => {
  const filteredItems = []
  const urls = []

  for (const item of items) {
    let valid = true
    if (has(item, "url") && item.url) {
      if (!urls.includes(item.url)) {
        urls.push(item.url)
        valid = true
      } else {
        valid = false // duplicated
      }
    }

    if (has(item, "children") && item.children.length) {
      item.children = returnChildrenWithoutDuplicatedCustom(item.children)
    }

    if (valid) filteredItems.push(item)
  }

  return filteredItems
}

export const removeDuplicatedCustomItems = data => {
  data.children = returnChildrenWithoutDuplicatedCustom(data.items)
  return JSON.parse(JSON.stringify(data))
}
