import axios from 'axios'
import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import Toast from 'vue-toastification'
import VueAgile from 'vue-agile'
import { createI18n } from 'vue-i18n'
import VueImageZoomer from 'vue-image-zoomer'
import VueGoogleMaps from '@fawmi/vue-google-maps'

import 'vue-toastification/dist/index.css'
import 'vue-image-zoomer/dist/style.css'

import { ensureSession, cleanExpired } from '@/services/session'

ensureSession()
cleanExpired()

import App from '@/App.vue'
import router from '@/router'

import { IonicVue } from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'

// Store
import { store, locale } from '@/store.js'
import endpointCalculator from '@/services/endpoint_calculator.js'

const factory = async () => {
  const endpoint = endpointCalculator()
  const messagesResponse = await axios.get(`${endpoint}/i18n/ui.json`, {withCredentials: false})
  const messages = messagesResponse.data

  const i18n = createI18n({
    locale,
    messages,
  })

  const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(VueAgile)
    .use(VueImageZoomer)
  const head = createHead()

  app.use(mdiVue, {
    icons: mdijs,
  })
  app.use(store)
  app.use(head)
  app.use(i18n)
  app.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyAiZ76rnbnhVeVMVfEz0LdOcXwHVqCNZYY',
    },
  })

  const toastOptions = {}
  app.use(Toast, toastOptions)

  router.isReady().then(() => {
    app.mount('#app')
  })
}

factory()
  .then()
  .catch((err) => {
    throw err
  })
