export const getValue = (key) => {
  const value = window.localStorage.getItem(key)
  if (!value) return null
  let parsedValue
  try {
    parsedValue = JSON.parse(value)
  } catch (err) {
    return null
  }
  return parsedValue
}

export const setValue = (key, value) => {
  return window.localStorage.setItem(key, JSON.stringify(value))
}

export const removeKey = (key) => {
  return window.localStorage.removeItem(key)
}
