import axios from 'axios'
import endpointCalculator from '@/services/endpoint_calculator.js'
import rustEndpointCalculator from '@/services/rust_endpoint_calculator.js'
import { getValue, setValue } from '@/services/persistence.js'
import { isEmpty, extend, isString } from 'underscore'

axios.defaults.withCredentials = true

const endpoint = endpointCalculator()
const rustEndpoint = rustEndpointCalculator()

// Returns null or the country string as uppercase
const getCountry = async () => {
  // Check if forced IP is on the local storage
  let forcedIp = getValue('FORCED_IP')
  let getCountryPath = `${rustEndpoint}/rs/find-country`

  if (isEmpty(forcedIp)) forcedIp = ""
  const countryResponse = await axios({
    method: 'post',
    url: getCountryPath,
    data: { forced_ip: forcedIp },
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const data = countryResponse.data
  const country = data.country

  if (!isString(country)) return null
  return country.toUpperCase()
}

const buildQuery = async () => {
  let query = '?'

  let language = getValue('locale')
  // set default language if not defined
  if (!language) {
    const country = await getCountry()

    if (country === 'PT') {
      language = 'pt'
    } else {
      // fallback language
      language = 'es'
    }
  }

  if (language) setValue('locale', language)

  query += `language=${language}`

  return query
}

export default {
  getEndpoint() {
    return endpoint
  },
  createAddress: async function (
    token,
    alias,
    nif,
    phone,
    fullname,
    address1,
    address2,
    zip_code,
    administrative_zone,
    city,
    country
  ) {
    return await axios.post(`${endpoint}/v1/core/my-account/create-address/`, {
      token,
      alias,
      nif,
      phone,
      fullname,
      address1,
      address2,
      zip_code,
      administrative_zone,
      city,
      country,
    })
  },
  getAddresses: async function(token) {
    return await axios.post(`${endpoint}/v1/core/my-account/get-address/`, { token })
  },
  updateAddress: async function (
    token,
    pk,
    alias,
    nif,
    phone,
    fullname,
    address1,
    address2,
    zip_code,
    administrative_zone,
    city,
    country
  ) {
    return await axios.post(`${endpoint}/v1/core/my-account/update-address/`, {
      token,
      pk,
      alias,
      nif,
      phone,
      fullname,
      address1,
      address2,
      zip_code,
      administrative_zone,
      city,
      country,
    })
  },
  deleteAddress: async function (token, pk) {
    return await axios.post(`${endpoint}/v1/core/my-account/delete-address/`, {
      token,
      pk,
    })
  },
  getCountry: async function () {
    // proxy to method
    return await getCountry()
  },
  getContent: async function (slug, shop) {
    const query = await buildQuery()
    return await axios.get(
      `${endpoint}/v1/content/fetch/${slug}/${query}&shop=${shop}`
    )
  },
  getContentSlugs: async function (shop) {
    const query = await buildQuery()
    return await axios.get(
      `${endpoint}/v1/content/fetch-slugs/${query}&shop=${shop}`
    )
  },
  getShop: async function (name, customer_group = null) {
    let query = await buildQuery()
    if (customer_group) {
      query += `&customer_group=${customer_group}`
    }
    return await axios.get(`${endpoint}/v1/core/shop/${name}/${query}`)
  },
  getEntries: async function (shop) {
    const query = await buildQuery()
    return await axios.get(
      `${endpoint}/v1/content/entries/${query}&shop=${shop}`
    )
  },
  getEntry: async function (shop, slug) {
    const query = await buildQuery()
    return await axios.get(
      `${endpoint}/v1/content/entries/${slug}/${query}&shop=${shop}`
    )
  },
  login: async function (username, password) {
    return await axios.post(`${endpoint}/v1/core/login/`, {
      username,
      password,
    })
  },
  register: async function (
    username,
    password,
    firstname,
    lastname,
    newsletter,
    language,
    shop,
    business
  ) {
    const postData = {
      username,
      password,
      firstname,
      lastname,
      language,
      shop,
    }

    if (!isEmpty(business)) postData.business = business
    if (newsletter) postData.newsletter = '1'
    return await axios.post(`${endpoint}/v1/core/register/`, postData)
  },
  getProductImage: async function(pk) {
    return await axios.get(`${endpoint}/v1/products/image/${pk}/`)
  },
  getProductDetail: async function (shop, pk, token, extraData = {}) {
    let postData = extend(
      {
        shop,
      },
      extraData
    )
    if (token) postData.token = token
    return await axios.post(`${endpoint}/v1/products/detail/${pk}/`, postData)
  },
  remember: async function (username, domain) {
    return await axios.post(`${endpoint}/v1/core/remember/`, {
      username,
      domain,
    })
  },
  consumeRemember: async function (remember_token, password) {
    return await axios.post(`${endpoint}/v1/core/consume-remember/`, {
      remember_token,
      password,
    })
  },
  updateAccount: async function (token = null, shop, data) {
    let postData = extend(data, { shop })
    if (token) postData.token = token
    return await axios.post(`${endpoint}/v1/core/update/`, postData)
  },
  createCart: async function (token = null, shop) {
    let postData = { shop }
    if (token) postData.token = token
    return await axios.post(`${endpoint}/v1/cart/cart/`, postData)
  },
  hasRemoteCart: async function (token) {
    let postData = { token }
    return await axios.post(`${endpoint}/v1/cart/has-remote-cart/`, postData)
  },
  myAccount: async function (token) {
    let postData = { token }
    return await axios.post(`${endpoint}/v1/core/my-account/`, postData)
  },
  updateMyAccount: async function (token, data) {
    let postData = extend(data, { token })
    return await axios.post(`${endpoint}/v1/core/update-my-account/`, postData)
  },
  applyPromotionalCode: async function (uuid, code, shop) {
    const postData = {
      uuid,
      code,
      shop,
      action: 'promotional_code',
    }
    return await axios.post(`${endpoint}/v1/cart/cart/${uuid}/`, postData)
  },
  removePromotionalCode: async function (uuid) {
    const postData = {
      uuid,
      action: 'remove_promotional_code',
    }
    return await axios.post(`${endpoint}/v1/cart/cart/${uuid}/`, postData)
  },
  closeCart: async function (uuid, token, payment_method, address) {
    const isAlias = isString(address)
    let postData = {}

    // preparing address in base of alias
    if (!isAlias) {
      // regular address sending
      postData = extend(address, {
        action: 'close',
        payment_method,
        token,
        uuid,
      })
    } else {
      // alias sending
      postData = {
        action: 'close',
        payment_method,
        alias: address,
        token,
        uuid,
      }
    }

    return await axios.post(`${endpoint}/v1/cart/cart/${uuid}/`, postData)
  },
  getCart: async function (uuid, token = null, country = 'ES') {
    const postData = {
      action: 'show',
      uuid,
      country,
    }

    if (token) postData.token = token
    return await axios.post(`${endpoint}/v1/cart/cart/${uuid}/`, postData)
  },
  addProductToCart: async function (uuid, sku, qty) {
    const postData = {
      action: 'add',
      uuid,
      sku,
      qty,
    }
    return await axios.post(`${endpoint}/v1/cart/cart/${uuid}/`, postData)
  },
  removeProductToCart: async function (uuid, sku, qty) {
    const postData = {
      action: 'remove',
      uuid,
      sku,
      qty,
    }
    return await axios.post(`${endpoint}/v1/cart/cart/${uuid}/`, postData)
  },
  search: async function (
    shop,
    page = 1,
    limit = 16,
    newPostData = {},
    token = null
  ) {
    let postData = extend(newPostData, {
      shop,
      page,
      limit,
      search_mode: 'V2',
    })
    if (token) postData.token = token
    return await axios.post(`${endpoint}/v1/products/search/`, postData)
  },
  productUpsells: async function (shop, sku, token = null) {
    let postData = {
      shop,
      sku,
    }
    if (token) postData.token = token
    return await axios.post(`${endpoint}/v1/products/upsells/`, postData)
  },
  productContents: async function (shop, sku, language) {
    let postData = {
      shop,
      language,
    }
    return await axios.post(
      `${endpoint}/v1/products/contents/${sku}/`,
      postData
    )
  },
  productQuery: async function (shop, q, language, token = null) {
    let postData = {
      shop,
      language,
      q,
    }
    if (token) postData.token = token
    return await axios.post(`${endpoint}/v1/products/query/`, postData)
  },
  productRelated: async function (shop, sku, token = null) {
    let postData = {
      shop,
      sku,
    }
    if (token) postData.token = token
    return await axios.post(`${endpoint}/v1/products/related/`, postData)
  },
  contact: async function (shop, email, name, phone, text) {
    const postData = {
      shop,
      email,
      name,
      phone,
      text,
    }
    return await axios.post(`${endpoint}/v1/core/contact/`, postData)
  },
  productContact: async function (
    shop,
    email,
    subject,
    text,
    sku,
    name,
    phone
  ) {
    const postData = {
      shop,
      email,
      subject,
      text,
      sku,
      name,
      phone,
    }
    return await axios.post(`${endpoint}/v1/products/contact/`, postData)
  },
  getShopSideBanners: async function (name, path, categories = null) {
    const query = await buildQuery()
    let postData = { path }
    if (categories) postData.categories = categories
    return await axios.post(
      `${endpoint}/v1/core/side-banners/${name}/${query}`,
      postData
    )
  },
  menuTreeOutlet: async function (name, segment = null, token = null) {
    const query = await buildQuery()
    let postData = { outlet: '1' }
    if (segment) postData.segment = segment
    if (token) postData.token = token
    return await axios.post(
      `${endpoint}/v1/core/tree/${name}/${query}`,
      postData
    )
  },
  menuTree: async function (name, segment = null, token = null) {
    const query = await buildQuery()
    let postData = {}
    if (segment) postData.segment = segment
    if (token) postData.token = token
    return await axios.post(
      `${endpoint}/v1/core/tree/${name}/${query}`,
      postData
    )
  },
  backToStock: async function (email, sku) {
    const query = await buildQuery()
    let postData = {email, sku}
    return await axios.post(
      `${endpoint}/v1/products/register-back-to-stock/`,
      postData
    )
  },
  rootMenuTree: async function (shop, token = null, segment = null) {
    const query = await buildQuery()
    let postData = {}
    if (token) postData.token = token
    if (segment) postData.segment = segment
    return await axios.post(
      `${endpoint}/v1/core/root-tree/${shop}/${query}`,
      postData
    )
  },
  getCategory: async function (category, shop, language, token = null) {
    let postData = { category, shop, language }
    if (token) {
      postData.token = token
    }
    return await axios.post(`${endpoint}/v1/core/category/`, postData)
  },
  me: async function (token) {
    return await axios.post(`${endpoint}/v1/core/me/`, { token })
  },
  registerNewsletter: async function (language, shop, email) {
    return await axios.post(`${endpoint}/v1/core/newsletter/`, {
      language,
      shop,
      email,
    })
  },
  confirmationNewsletter: async function (identifier) {
    return await axios.get(`${endpoint}/v1/core/newsletter/confirmation/${identifier}/`)
  },
}
