import delay from 'delay'
import dayjs from 'dayjs'
import { compact, has, isNumber, sortBy, clone, isString } from 'underscore'

const SAFETY_MARGIN = 120
const SAFETY_MARGIN_BETWEEN_ELEMENTS = 40

export const applyItemsOrder = (items, parent=null, caller=null) => {
  let orderedItems = []

  let orderField = null
  if (parent) orderField = parent.order_field_categories

  // Order current items
  if (!orderField) {
    // as it is
    orderedItems = items
  } else if(orderField === 'new') {
    // by newest product
    orderedItems = sortBy(items, item => {
      if (caller == "category" && isString(item.newest_product) && /^\d{4}-\d{2}-\d{2}$/.test(item.newest_product)) {
        const ts = dayjs(item.newest_product)
        return ts.unix() * -1
      }
      return -1 * parseInt(item.newest_product, 10)
    })
  } else {
    // by delta
    orderedItems = sortBy(items, item => {
      return item.delta
    })
  }

  // inner children
  for (const item of orderedItems) {
    if (item.children && item.children.length) {
      item.children = applyItemsOrder(item.children, item)
    }
  }

  return orderedItems
}

export const computeBestDistribution = async (items) => {
  const names = []
  const namesWithChildren = []

  for (const item of items) {
    names.push(item.name.text)
    if (item.children && item.children.length > 0) namesWithChildren.push(item.name.text)
  }


  // fill the menu-oos
  for (let i = 0; i < names.length; i++) {
    const element = document.getElementById(`menu-item-oos-${i}`)
    element.innerHTML = names[i]
  }

  await delay(0.1)

  // create stacks with size
  const stacks = []
  const maxStackWidth = window.innerWidth - SAFETY_MARGIN

  let currentStack = []
  let currentStackWidth = 0

  for (var i = 0; i < names.length; i++) {
    const currentName = names[i]
    const element = document.getElementById(`menu-item-oos-${i}`)
    let width = element.offsetWidth + SAFETY_MARGIN_BETWEEN_ELEMENTS

    // detect if children
    if (namesWithChildren.includes(currentName)) width += 25

    let nextWidth = currentStackWidth + width

    if (nextWidth >= maxStackWidth) {
      // Liberate new stack
      stacks.push(currentStack.map(item => item))
      currentStack = [currentName]
      currentStackWidth = 0
    } else {
      // Continue stacking
      currentStack.push(currentName)
      currentStackWidth += clone(width)
    }
  }

  if (currentStack.length) {
    // liberate the last items
    stacks.push(clone(currentStack))
    currentStack = []
  }

  const resultStacks = []
  let pointer = 0
  for (const stack of stacks) {
    let resultStack = []
    for (const item of stack) {
      resultStack.push(items[pointer])
      pointer += 1
    }

    resultStacks.push(resultStack)
  }

  return resultStacks
}

export const cleanChildren = (children, visibleCategories) => {
  const clean = []

  for (const child of children) {
    if (has(child, 'enabled') && !child.enabled) continue
    if (has(child.pk) && !visibleCategories.includes(child.pk)) continue

    if (has(child.children) && child.children.length) {
      child.children = cleanChildren(child.children, visibleCategories)
    }

    clean.push(JSON.parse(JSON.stringify(child)))
  }

  return clean
}

export const filterMenuItems = (items, visibleCategories, shopName, customer_group, $store) => {
  let dataItems = items.map((item) => {
    // if no slug more than number - remove it
    if (!/^\d+-.+$/.test(item.slug)) return null
    if (item.always_visible === true) return item

    if (shopName == "jpujol" && $store) {
      // agujas maquina -> pk 11 should not appear
      const segment = $store.state.segment
      if (segment) {
        const segmentSlug = segment.slug
        if (segmentSlug != "jpujol" && item.pk == 11) {
          return null
        }
      }
    }

    if (shopName == "ideaspatch") {
      // apply coleccion_visible
      const str_customer_group = `${customer_group}`
      if (item.coleccion_visible && !item.coleccion_visible.includes(str_customer_group)) {
        return null
      }
    }

    // check if it is visible for the customer group
    if (isNumber(customer_group) && customer_group > 0) {
      if (!item[`visible_on_group_${customer_group}`]) return null
    } else {
      // we suppose customer_group == 1
      if (!item["visible_on_group_1"]) return null
    }

    if (!item.shop) return item
    if (item.shop && shopName != item.shop) return null

    // if is not enabled - remove it
    if (has(item, 'enabled')) {
      if (!item.enabled) return null
    }

    if (item.children && item.children.length) {
      item.children = cleanChildren(item.children, visibleCategories)
    }

    return item
  })

  dataItems = compact(dataItems)

  return dataItems
}
