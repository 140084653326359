const LOCAL_ENDPOINT = 'http://api.jpujol.local:15000'
const QA_ENDPOINT = 'https://apijpujolrust.cyangecko.com'
const PROD_ENDPOINT = 'https://api-jpujol-rust-prod.cyangecko.com'

export default function () {
  return PROD_ENDPOINT
  // return QA_ENDPOINT
  const hostname = window.location.hostname

  // QA
  if (/jpujol\.local/.test(hostname)) return QA_ENDPOINT
  if (!/localhost/.test(hostname) && /local/.test(hostname)) return QA_ENDPOINT
  if (/cyangecko/.test(hostname) && !/prod/.test(hostname)) return QA_ENDPOINT

  // DEV
  if (/localhost/.test(hostname)) return LOCAL_ENDPOINT

  // PROD
  return PROD_ENDPOINT
}
