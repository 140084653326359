import HomePage from '@/views/HomePage.vue'
import ProductPage from '@/views/ProductPage.vue'
import RegisterPage from '@/views/RegisterPage.vue'
import ContactPage from '@/views/ContactPage.vue'
import ChangePassword from '@/views/ChangePassword.vue'
import LoginPage from '@/views/LoginPage.vue'
import SegmentHome from '@/views/SegmentHome.vue'
import AccountPage from '@/views/AccountPage.vue'
import OutletPage from '@/views/OutletPage.vue'
import CartPage from '@/views/CartPage.vue'
import BlogPage from '@/views/BlogPage.vue'
import EntryPage from '@/views/EntryPage.vue'
import ContentPage from '@/views/ContentPage.vue'
import CardOk from '@/views/CardOk.vue'
import CardKo from '@/views/CardKo.vue'
import SearchTelasPage from '@/views/SearchTelasPage.vue'
import CategoryPage from '@/views/CategoryPage.vue'
import OutletCategoryPage from '@/views/OutletCategoryPage.vue'
import SearchPage from '@/views/SearchPage.vue'
import LogoutPage from '@/views/LogoutPage.vue'
import WirePayment from '@/views/WirePayment.vue'
import NewsletterConfirmation from '@/views/NewsletterConfirmation.vue'
import PujolPayment from '@/views/PujolPayment.vue'
import { createRouter, createWebHistory } from '@ionic/vue-router'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/desconectar',
    name: 'LogoutPage',
    component: LogoutPage,
  },
  {
    path: '/compra',
    name: 'CartPage',
    component: CartPage,
  },
  {
    path: '/confirmacion-newsletter/:identifier',
    props: true,
    name: 'NewsletterConfirmation',
    component: NewsletterConfirmation,
  },
  {
    path: '/password/change/:remember_token',
    name: 'ChangePassword',
    component: ChangePassword,
    props: true,
  },
  {
    path: '/outlet',
    name: 'OutletPage',
    component: OutletPage,
  },
  {
    path: '/pago/jpujol/:uuid',
    props: true,
    name: 'PujolPayment',
    component: PujolPayment,
  },
  {
    path: '/pago/transferencia-bancaria/:uuid',
    props: true,
    name: 'WirePayment',
    component: WirePayment,
  },
  {
    path: '/pago/tarjeta/ok/:uuid/:order',
    props: true,
    name: 'CardOk',
    component: CardOk,
  },
  {
    path: '/pago/tarjeta/ko/:uuid/:order',
    props: true,
    name: 'CardKo',
    component: CardKo,
  },
  {
    path: '/seccion/:slug',
    props: true,
    name: 'SegmentHome',
    component: SegmentHome,
  },
  {
    path: '/blog',
    name: 'BlogPage',
    component: BlogPage,
  },
  {
    path: '/blog/:slug',
    props: true,
    name: 'EntryPage',
    component: EntryPage,
  },
  {
    path: '/contacto',
    name: 'ContactPage',
    component: ContactPage,
  },
  {
    path: '/busqueda/:query',
    props: true,
    name: 'SearchPage',
    component: SearchPage,
  },
  {
    path: '/category/:slug',
    props: true,
    name: 'CategoryPage',
    component: CategoryPage,
  },
  {
    path: '/outlet-category/:slug',
    props: true,
    name: 'OutletCategoryPage',
    component: OutletCategoryPage,
  },
  {
    path: '/telas/:segment/:value',
    props: true,
    name: 'SearchTelasPage',
    component: SearchTelasPage,
  },
  {
    path: '/conectar/:type',
    name: 'LoginPage',
    component: LoginPage,
    props: true,
  },
  {
    path: '/mi-cuenta',
    name: 'AccountPage',
    component: AccountPage,
  },
  {
    path: '/registro/:type',
    name: 'RegisterPage',
    component: RegisterPage,
    props: true,
  },
  {
    path: '/productos/:slug',
    props: true,
    name: 'ProductPage',
    component: ProductPage,
  },
  {
    path: '/contenido/:slug',
    props: true,
    name: 'ContentPage',
    component: ContentPage,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
