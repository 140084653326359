import { detectShop } from '@/services/ecommerce.js'

export const baseMeta = () => {
  return [
    {
      name: 'application-shop',
      content: detectShop(),
    },
  ]
}

export const baseLink = () => {
  const shop = detectShop()
  let type
  let favicon

  if (shop) {
    if (shop.toLowerCase() === 'elna') {
      favicon = 'https://api.jpujol.com/opt/files/icone_web_elna.jpg'
      type = 'image/jpeg'
    } else if (shop.toLowerCase() === 'handiquilter') {
      favicon = 'https://api.jpujol.com/opt/files/icone_web_handi.jpg'
      type = 'image/jpeg'
    } else if (shop.toLowerCase() === 'jack') {
      favicon = 'https://api.jpujol.com/opt/files/icone_web_jack.jpg'
      type = 'image/jpeg'
    } else if (shop.toLowerCase() === 'juki') {
      favicon = 'https://api.jpujol.com/opt/files/icone_web_juki.jpg'
      type = 'image/jpeg'
    } else if (shop.toLowerCase() === 'necchi') {
      favicon = 'https://api.jpujol.com/opt/files/necchi_PETIT.png'
      type = 'image/png'
    } else if (shop.toLowerCase() === 'jpujol') {
      favicon = 'https://api.jpujol.com/opt/files/jpujol.jpg'
      type = 'image/jpeg'
    } else if (shop.toLowerCase() === 'ideaspatch') {
      favicon = 'https://api.jpujol.com/opt/files/ideaspatch.jpeg'
      type = 'image/jpeg'
    }
  }

  if (!favicon) return []

  const faviconMeta = {
    rel: 'shortcut icon',
    href: favicon,
    type,
  }

  return [faviconMeta]
}

export const calculateTitle = (str) => {
  return `${str} - ${detectShop()} - JPUJOL`
}
