import { keys, isNumber } from 'underscore'
import { createStore } from 'vuex'
import { getValue, setValue } from '@/services/persistence.js'

const storedLocale = getValue('locale')
const storedCart = getValue('cart')
const storedToken = getValue('token')
let storedSegment = getValue('segment')
if (storedSegment) storedSegment = JSON.parse(storedSegment)

export const locale = storedLocale ? storedLocale : 'es'

/**
 * Recalculates the next computed fields:
 *
 * isPurchaseAllowed
 */
const recalculateComputed = (state) => {
  /*
   * isPurchaseAllowed
   */
  const hasCustomerGroup = isNumber(state.customer_group)

  if (hasCustomerGroup) {
    state.isPurchaseAllowed = state.customer_group != 6
  }
}

export const store = createStore({
  state() {
    return {
      country: null,
      shopInfo: null,
      floatCategories: [],
      allowedRoots: [],
      outletCategories: [],
      segment: storedSegment ? storedSegment : null,
      cart: storedCart ? storedCart : null,
      token: storedToken ? storedToken : null,
      categoriesSelected: [],
      visibleCategories: [],
      isPurchaseAllowed: false,
      tree: [],
      rawTree: [],
      customer_group: null,
      locale,
      listview: null,
      listpage: null,
    }
  },
  mutations: {
    clean(state) {
      state.shopInfo = null
      state.floatCategories = []
      state.outletCategories = []
      state.segment = null
      state.cart = null
      state.token = null
      state.categoriesSelected = []
      state.visibleCategories = []
      state.tree = []
      state.locale = null
      state.listpage = null
    },
    update(state, payload) {
      const payloadKeys = keys(payload)
      for (const key of payloadKeys) {
        state[key] = payload[key]

        // persist locale on local storage
        const localStorageKeys = ['locale', 'cart', 'token']
        if (localStorageKeys.includes(key)) {
          setValue(key, payload[key])
        }

        const jsonLocalStorageKeys = ['segment']
        if (jsonLocalStorageKeys.includes(key)) {
          setValue(key, JSON.stringify(payload[key]))
        }

        // locale cannot be not defined
        // fallback always to "es"
        if (!state.locale) {
          state.locale = 'es'
          setValue('locale', 'es')
        }
      }

      recalculateComputed(state)
    },
  },
})
