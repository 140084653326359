import api from '@/services/api.js'
import PubSub from 'pubsub-js'
import { first, isEmpty, has } from 'underscore'

export const emitCategorySelected = async (route, shop, locale) => {
  const name = route.name
  const params = route.params

  if (name === 'CategoryPage' && has(params, "slug")) {
    const slug = params.slug

    let categoryId = first(slug.split('-'))
    if (!isEmpty(categoryId) && /\d+/.test(categoryId)) {
      categoryId = parseInt(categoryId, 10)
      const categoryResponse = await api.getCategory(categoryId, shop, locale)
      const data = categoryResponse.data
      const pks = [categoryId]

      for (const parent of data.parents) {
        pks.push(parent.pk)
      }

      PubSub.publish('category.enter', pks)
    } else {
      PubSub.publish('category.exit')
    }
  } else {
    PubSub.publish('category.exit')
  }
}
