import { isEmpty } from 'underscore'
import { getValue } from '@/services/persistence.js'

export const detectShop = () => {
  // check if forced by local storage
  const lsValue = getValue('SHOP')
  let hostname
  if (!isEmpty(lsValue)) {
    hostname = lsValue
  }

  // fallback by hostname
  if (!hostname) hostname = window.location.hostname
  if (hostname.toUpperCase().includes('ELNA')) return 'ELNA'
  if (hostname.toUpperCase().includes('HANDIQUILTER')) return 'HANDIQUILTER'
  if (hostname.toUpperCase().includes('JACK')) return 'JACK'
  if (hostname.toUpperCase().includes('JUKI')) return 'JUKI'
  if (hostname.toUpperCase().includes('NECCHI')) return 'NECCHI'
  if (hostname.toUpperCase().includes('IDEASPATCH')) return 'IDEASPATCH'

  // fallback value
  return 'JPUJOL'
}
