import axios from 'axios'
import { getValue, setValue, removeKey } from "@/services/persistence"

export const ensureSession = () => {
  // TODO
}

export const cleanExpired = () => {
  // TODO
}

export const getRemoteSession = async () => {
  // Use PHP server
  try {
    const response = await axios.get(`${endpoint}/php/session.php`)
    const data = response.data
    return data.token
  } catch(err) {
  }

  return null
}

export const setRemoteSession = async () => {
  try {
    return await axios.get(`${endpoint}/php/session.php?token=${token}`)
  } catch(err) {
  }

  return null
}
