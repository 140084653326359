import { removeKey } from '@/services/persistence'

export const analyzeCloseCartResult = (data) => {
  const { status } = data.data
  return status !== 'open'
}

export const purgeCartData = async () => {
  await removeKey('cart')
}
