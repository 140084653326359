import { isArray, union } from 'underscore'

export const getCategoriesList = (tree) => {
  let list = []

  if (isArray(tree)) {
    for (const item of tree) {
      list.push(item.pk)
      if (item.parent) list = union(list, getCategoriesList([item.parent]))
    }
  }

  return list
}
