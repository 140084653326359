import { isObject, isArray } from 'underscore'

export default {
  methods: {
    findTranslation(items, locale) {
      let text = null

      if (isObject(items) && !isArray(items)) {
        // is an object
        text = items.text
      } else {
        for (const item of items) {
          if (item.language === locale) {
            text = item.text
          }
        }
      }

      return text
    },
  },
}
